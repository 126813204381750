<template>
   <div class="baseSetup">
       <div class="scroll-box">
            <div class="tr-li">
                <div class="from-input"><el-checkbox v-model="isInputBoard" label="是否开启手写板功能" ></el-checkbox></div>
            </div>
            <div class="tr-li">
                <div class="from-input"><el-checkbox v-model="isRedCardLogin" label="是否开启读卡登录" ></el-checkbox></div>
            </div>
            <div class="tr-li">
                <div class="from-input"><el-checkbox v-model="isAuxiliaryScreen" label="是否开启副屏功能" ></el-checkbox></div>
            </div>
            <div class="tr-li">
                <div class="from-input"><el-checkbox v-model="isCallLocalSound" label="取餐叫号播放声源取本地文件" ></el-checkbox></div>
            </div>
            <div class="tip">
                注:本功能只支持WIndows版本+KDS电脑本地播报，播放文件在当前目录voice文件夹下
            </div>
       </div>
        <div class="footer-box">
            <button class="btn" @click="back()">返回</button>
            <button class="btn selected" @click="saveConfig()">保存配置</button>
        </div>
   </div>
</template>

<script>
export default {
    name:'baseSetup',
    data(){
        return {
            /**是否开启手写板功能*/
            isInputBoard:false,
            /**是否开启读卡登录 */
            isRedCardLogin:false,
            /*是否开启副屏功能*/
            isAuxiliaryScreen:false,
            //取餐叫号播放声源取本地文件
            isCallLocalSound:false
        }
    },
    mounted(){
        this.$webBrowser.getBaseSetup().then(d=>{
            if(d){
                this.isInputBoard=d.isInputBoard===true;
                this.isRedCardLogin=d.isRedCardLogin===true;
                this.isAuxiliaryScreen=d.isAuxiliaryScreen===true;
                this.isCallLocalSound=d.isCallLocalSound===true;
            }
        })
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /**保存配置 */
        saveConfig(){
            let data={
                isInputBoard:this.isInputBoard,
                isRedCardLogin:this.isRedCardLogin,
                isAuxiliaryScreen:this.isAuxiliaryScreen,
                isCallLocalSound:this.isCallLocalSound
            }
            this.$webBrowser.saveBaseSetup(data).then(d=>{
                if(d && d.state==0){
                    this.$message.success("保存成功");
                    if(this.isAuxiliaryScreen){
                        this.$cacheData.DeskPosconfigure().then((d)=>{
                            let PosGuestScreen={};
                            if(d){
                                PosGuestScreen.PosGuestScreen_LoopImages=d.PosGuestScreen_LoopImages;//POS副屏轮播图
                                PosGuestScreen.PosGuestScreenNotShowingBillDetail=d.PosGuestScreenNotShowingBillDetail;//关闭POS副屏显示账单明细
                                PosGuestScreen.PosGuestScreen_LoopInterval=d.PosGuestScreen_LoopInterval;//POS副屏轮播间隔，单位：秒
                            }
                            this.$webBrowser.openAuxiliaryScreen({
                                PosGuestScreen
                            });//显示副屏
                        }).catch(e=>{
                            console.log("基本配置获取失败："+e.message);
                            this.$webBrowser.openAuxiliaryScreen();//显示副屏
                        })
                        
                    }else{
                        this.$webBrowser.auxiliaryScreenClose();//关闭副屏
                    }
                }
            });
            
        }
    }
}
</script>

<style lang="scss">
@import './baseSetup.scss';
</style>